<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request.js';
import productList from './table/table.js';

formCreate.component('productList', productList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [], // 渲染表单

    };
  },
  async created() {
    const res = await this.getFormRule('cps_dealers_integral_form');
    console.log(res);
    console.log(this.formConfig.id);
    // const newRule = [];
    // rule.forEach((item) => {
    //   const v = item;
    //   if (v.field === 'reset_password') {
    //     newRule.push({
    //       type: 'resetPassword',
    //       field: 'reset_password',
    //       title: '',
    //       value: {},
    //     });
    //   } else {
    //     newRule.push(v);
    //   }
    // });
    // this.rule = newRule;
    // this.reload(this.rule);
  },
  methods: {
    formComplete() {
      this.getDetail();
    },
    getDetail() {
      request
        .get('/cps-mall/v1/order/agentOrderDetails/findOrderById', { id: this.formConfig.id })
        .then((res) => {
          if (res.success) {
            let goodsName = '';
            let number = 0;
            if (res.result.goodsList) {
              res.result.goodsList.forEach((item, index) => {
                if (index < res.result.goodsList.length - 1) {
                  goodsName += `${item.goodsName}；`;
                } else {
                  goodsName += `${item.goodsName}`;
                }
                number += item.number;
              });
            }
            const result = {
              ...this.formConfig,
              ...res.result.baseInfo,
              ...res.result.details,
              productList: res.result.goodsList.map((item) => ({
                ...item, price: item.price * item.number,
              })),
              goodsName,
              number,
              totalPrice: this.formConfig.totalPrice,
            };
            this.setValue(result);
          }
        });
    },
  },
};
</script>
